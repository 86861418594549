.not-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.not-result-icon {
  margin-top: 1rem;
  padding: 5px;
  font-size: 1.5rem;
  color: rgb(9, 9, 65);
}

.not-result-message {
  font-size: 1rem;
  text-align: center;
  padding: 0 1rem;
  color: rgba(51, 51, 51, 0.712);
}

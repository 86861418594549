.feedback-content-box {
  background-color: rgba(0, 0, 128, 0.144);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-content {
  background-color: #fff;
  padding: 2rem;
  width: 60%;
}

.feedback-content h3 {
  font-size: 1.4rem;
  color: #666;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .feedback-content {
    width: 90%;
  }
}

.market-news-heading {
  font-size: 2.2rem;
  color: #555;
}

.more-news-title {
  color: #666;
  font-size: 1.1rem;
  font-weight: 600;
}

.more-news-links {
  color: orangered;
  text-decoration: underline;
  font-size: 1.1rem;
  padding: 0.5rem 0;
}

.market-news-body {
  color: #222;
  font-size: 1rem;
}

.what-we-do_container {
  display: flex;
  justify-content: space-between;
}

.what-we-do_icon {
  border: 1px solid #666;
  color: black;
  padding: 15px;
  border-radius: 50%;
}

.what-we-do_text {
  margin-left: 2rem;
}

.what-we-do_text > p {
  color: #111;
  font-weight: 400;
  line-height: 2rem;
  font-size: 1rem;
}
.what-we-do_text > h2 {
  font-size: 1.2rem;
  font-weight: 600;
}

.what-we-do-action_btn {
  border: 1px solid #777;
  padding: 5px 2rem;
  border-radius: 2rem;
  display: inline-block;
  margin-top: 1rem;
  cursor: pointer;
}

.what-we-do-action_btn:hover {
  border: 1px solid #fff;
  background-color: #254099;
  color: #fff;
}

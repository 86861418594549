.available-balance-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.4rem;
}

.available-balance-container h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #777;
}

.available-balance {
  background-color: rgba(19, 78, 13, 0.801);
  color: #fff;
  padding: 0;
  font-size: 0.8rem;
}

.revenue-monthly {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

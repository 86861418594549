.create-shipment-subtitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgba(24, 18, 44, 0.589);
}

.active-shipment-key {
  color: rgba(51, 51, 51, 0.856);
}

.active-shipment-value {
  color: #666;
  font-size: 0.9rem;
  font-weight: 400;
}

.toggle-warning {
  font-size: 0.8rem;
  color: orangered;
}

.for-investor {
  text-align: right;
  display: inline-block;
  color: #fff;
  padding: 3px;
  background-color: rgb(160, 42, 42);
  margin-bottom: 1rem;
}
.for-investor select {
  background-color: #fff !important;
}

.login-container {
  background: linear-gradient(60deg, #1b146463, #1b1464a4),
    url("../../../static-assets/images/login-truck-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-height: 100vh;
  padding-bottom: 1rem;
}

.login-container-header {
  display: flex;
  justify-content: center;
}

.login-form {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.login-form-card {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1.5rem;
  border-radius: 8px;
  width: 40%;
}

.login-form-card h2 {
  color: rgb(16, 16, 90);
  font-size: 1.5rem;
}

.login-forgot-password,
.login-forgot-password:hover {
  display: block;
  text-align: right;
  color: rgba(139, 0, 0, 0.8);
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 0.8rem;
  text-decoration: none;
}

.login-form-btn {
  display: block !important;
  color: #fff !important;
  background-color: rgba(16, 16, 90, 0.623) !important;
  border-radius: 3rem !important;
  width: 100% !important;
  font-size: 1.3rem !important;
  padding: 0.7rem !important;
}

.login-form-btn:hover {
  background-color: rgba(16, 16, 90, 0.945) !important;
}

.return-to-site,
.return-to-site:hover {
  text-decoration: underline;
  display: block;
  margin-top: 5px;
  color: #555;
  font-weight: bold;
}

.no-account {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  color: rgba(255, 255, 255, 0.74);
  font-size: 1.2rem;
}
.no-account span,
.no-account span:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 700px) {
  .login-form-card {
    width: 90%;
  }
}

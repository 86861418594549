.error-display-container {
  background-color: rgb(243, 11, 11);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin: 0;
  padding-top: 1rem;
  z-index: 100 !important;
}

.error-display-message-close {
  cursor: pointer;
}

.predicted-result {
  min-height: 90vh;
  background-color: rgba(255, 255, 255, 0.74);
  width: 95%;
  margin: 0 auto;
  padding: 2rem;
}

.predicted-result h2 {
  font-family: "Sansita Swashed", sans-serif;
  font-size: 1.3rem;
  display: inline-block;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding-bottom: 5px;
  color: #666;
}

.variables-column {
  background-color: rgba(255, 255, 255, 0.897);
  padding: 1rem;
  border-radius: 1rem;
}
.variables-column h2 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.432);
}

.variables-column p {
  font-size: 0.8rem;
  font-weight: bold;
  color: #777;
  text-transform: capitalize;
}

.result-column {
  padding: 1rem;
}

.result-column h2 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.432);
}

.diesel-factor-name {
  font-size: 0.8rem;
}

.result-table {
  background-color: #eee;
}

.back-btn {
  display: inline-block;
  background-color: #eee;
  padding: 0.5rem;
  margin: 0 0 1rem 0;
  border-radius: 2rem;
  cursor: pointer;
}

.back-btn:hover {
  background-color: #ffe;
}

.variable-title {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.investor-apply-form {
  margin: 2rem auto;
  width: 50%;
}

.investor-apply-form-title {
  font-size: 1.5rem;
  color: rgba(0, 0, 128, 0.774);
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .investor-apply-form {
    width: 90%;
  }
}

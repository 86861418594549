.our-promise_container {
  min-height: 40vh;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(
      45deg,
      rgb(255, 255, 255, 0.8),
      rgb(255, 255, 255, 0.8)
    ),
    url("../../../static-assets/images/computer-setup.jpg");
}

.our-promise {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  width: 60%;
  margin: 0 auto;
}

.our-promise_heading {
  color: rgba(0, 0, 0, 0.671);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: "Sansita Swashed", cursive;
}

.our-promise_text {
  color: #666;
  line-height: 2;
  text-align: center;
  font-size: 1rem;
}

@media screen and (max-width: 700px) {
  .our-promise {
    width: 100%;
  }
}

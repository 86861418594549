.foot-container {
  background-color: rgba(60, 75, 100, 1);
  color: #eee;
  bottom: 0;
}

.foot-container h3 {
  font-size: 1rem;
}

.footer-row {
  padding: 3em;
}

.company-description {
  color: #ccc;
  line-height: 1.5;
  display: block;
  font-size: 0.9rem;
}

.quick-links-container {
  list-style-type: circle;
}

.quick-links {
  color: #aaa;
  font-size: 0.9rem;
}

.quick-links:hover {
  text-decoration: underline;
  color: #ccc;
}

.copy-right-text {
  font-size: 0.9rem;
}

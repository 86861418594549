.about-page-bg-1 {
  background-color: rgba(238, 238, 238, 0.5);
}
.about-page-bg-2 {
  background-color: #fff;
}

.what-we-bring-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgba(16, 16, 90, 0.795);
  font-family: "Roboto";
  letter-spacing: 2px;
}

.user-profile-header {
  text-align: center;
  border-bottom: 1px solid #eee;
  padding: 1rem 0;
}

.user-profile-header-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
  color: #666;
}

.user-profile-data {
  padding: 2rem;
}
